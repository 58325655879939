import React, { useEffect, useState } from 'react'

const Events = () => {
  const [eventList, setEventList] = useState([]) as any[]

  const fetchAppointmentTypes = async () => {
    const res = await fetch(
      `${process.env.GATSBY_NOX_BASE_URL}/api/acuity?path=${encodeURI(
        '/api/v1/appointment-types'
      )}`,
      {
        method: 'GET'
      }
    )

    return await res.json()
  }

  const fetchCalendars = async () => {
    const res = await fetch(
      `${process.env.GATSBY_NOX_BASE_URL}/api/acuity?path=${encodeURI(
        '/api/v1/calendars'
      )}`,
      {
        method: 'GET'
      }
    )

    return await res.json()
  }

  /*
  eventList = [
    {
      id: 099787,
      name: Miami,
      events: [
        ...event,
        ...event
      ]
    }
  ]

  */

  useEffect(() => {
    if (!eventList.length) {
      fetchCalendars().then((initialJson) => {
        const cals = initialJson.map((cal) => {
          return {
            name: cal.name,
            id: cal.id
          }
        })

        setEventList(cals)
      })
    }

    if (eventList.length && !eventList[0].events) {
      const newArr = [] as any[]
      fetchAppointmentTypes().then((initialJson) => {
        eventList.forEach((cal) => {
          const localEvents = initialJson.filter(
            (event) =>
              event.calendarIDs.includes(cal.id) && event.category === 'Event'
          )
          newArr.push({ events: localEvents, ...cal })
        })
        setEventList(newArr)
      })
    }
  }, [eventList])

  return (
    <>
      <div>
        <h1>Cals</h1>
        {eventList &&
          eventList[0] &&
          eventList[0].events &&
          eventList[0].events.length &&
          eventList.map((location) => {
            return (
              <div key={location.name}>
                <h1 style={{ marginTop: '60px' }}>{location.name}</h1>
                {location.events.map((event) => {
                  return (
                    <p key={event.name} style={{ marginTop: '20px' }}>
                      name: {event.name}
                    </p>
                  )
                })}
              </div>
            )
          })}
      </div>
    </>
  )
}

export default Events
